import React, { useEffect, useReducer, useState, useContext } from "react"
import { graphql, Link, navigate } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import Lottie from "react-lottie"

import { brandBlock } from "utils/brand"
import ModalLayout from "components/modal-layout"
import SEO from "components/seo"
import { TextInput, Label, Checkbox, Required, Submit } from "components/forms"
import IconMail from "images/forms/email.inline.svg"
import MailIcon from "images/start/mail-postage-stamp.svg"
import emailAnimation from "images/start/open-letter.json"
import CouponContext from "context/coupon-context"
import BasicBackgroundSection from "components/basic-background-section"
import BgStart from "images/bg/start.jpeg"
import StyledBlock from "components/newsroom/styled-block"

import { getSharePreviewUrls } from "utils/share-preview"

const Container = styled.div`
  ${tw`text-white min-h-screen pb-16 pt-32 m:pt-40 flex flex-col items-center justify-center
       overflow-hidden mx-auto max-w-s m:max-w-m l:max-w-l`}

  max-width: 90%;

  & > ${StyledBlock} {
    ${tw`mb-8 l:mb-14`}

    max-width: 900px;
  }

  .content-wrapper {
    ${tw`w-full flex flex-col l:flex-row-reverse l:space-x-16 l:space-x-reverse`}

    .content {
      ${tw`w-full l:w-1/2 flex flex-wrap justify-center mx-auto`}

      max-width: 570px;

      .email {
        input {
          ${tw`w-full`}
        }

        & > label {
          top: -33px;
        }
      }
    }

    .hero-image {
      ${tw`w-full l:w-1/2 mt-12 l:mt-0 mx-auto`}

      max-width: 570px;
    }

    .sending {
      ${tw`flex flex-row justify-center`}

      ${StyledBlock} {
        h5 {
          ${tw`text-left ml-8 mt-8`}
        }
      }
    }
  }
`

const Header = styled.div`
  h1 {
    ${tw`py-2 w-full font-display font-bold text-white text-center`}
    font-size: 28px;
    line-height: 28px;
  }

  h4 {
    ${tw`text-yellow font-display font-bold text-center`}
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 640px) {
      font-size: 17px;
      line-height: 31px;
    }
  }
`

const Login = styled.div`
  ${tw`font-body text-center l:text-left mt-5`}
  color: #dddddd;
  font-size: 14px;
  line-height: 21px;

  a {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 2px;
  }
`

const AnimationContainer = styled.div`
  ${tw`mx-auto flex-shrink-0`}

  svg {
    path {
      fill: #5ef99a;
      stroke: #5ef99a;
    }
  }
`

const Start = ({ data, location }) => {
  const page = brandBlock(data.gcms.page)
  const [formEnabled, setFormEnabled] = useState(true)
  const [sendStatus, setSendStatus] = useState("unsent")
  const [error, setError] = useState(false)
  let initQS
  if (typeof window !== `undefined`) {
    initQS = window.location.search
  } else {
    initQS = null
  }
  const [queryString, setQueryString] = useState(initQS)
  const { coupon } = useContext(CouponContext)

  const [state, dispatch] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: "",
      opt_in_privacy_checkbox: false,
    }
  )

  const bgStack = [
    "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 46.71%, #000000 100%)",
    `url(${BgStart})`,
  ]

  function handleChange(event) {
    const target = event.target

    dispatch({
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    })
  }

  async function onSubmit(event) {
    setError(false)
    setSendStatus("sending")
    setFormEnabled(false)
    event.preventDefault()
    const url = `${process.env.GATSBY_DEMAND360_SIGNUP_API}/create_user`
    const data = {
      hutk: document.cookie.replace(
        /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      ),
      fields: state,
      coupon,
    }

    let body
    try {
      const response = await fetch(url, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": process.env.GATSBY_STRIPE_KEY,
        },
        body: JSON.stringify(data),
      })
      body = await response.json()
    } catch (err) {
      body = {}
    }

    if (body.status === "verified" && body.token?.length > 0) {
      navigate(`/start/2?token=${body.token}`)
    } else if (body.status === "subscribed" && typeof window !== "undefined") {
      window.location.href = process.env.GATSBY_DEMAND360_LOGIN
    } else if (body.status === "OK") {
      setSendStatus("sent")
    } else {
      setError(true)
      setSendStatus("unsent")
      setFormEnabled(true)
    }
  }

  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: emailAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => {
    // Prefill email if supplied
    const urlParams = new URLSearchParams(queryString)
    const prefilledEmail = urlParams.get("email")
    if (prefilledEmail?.length > 0) {
      dispatch({ email: prefilledEmail })
    }
  }, [queryString])

  return (
    <ModalLayout location={location}>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <BasicBackgroundSection bgStack={bgStack}>
        <Container>
          <StyledBlock
            dangerouslySetInnerHTML={{
              __html: page?.body?.html,
            }}
          />

          <div className="content-wrapper">
            <div className="content">
              {sendStatus === "unsent" && (
                <form onSubmit={onSubmit}>
                  <fieldset disabled={!formEnabled}>
                    {error && (
                      <div className="text-base font-body text-neonred text-center l:text-left">
                        There was an error. Please try again.
                      </div>
                    )}
                    <TextInput
                      className="email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={state.email}
                      placeholder="Your email"
                      required={true}
                      icon={IconMail}
                    />
                    <Label>
                      Parrot Analytics is committed to&nbsp;
                      <span className="underline">respecting your privacy</span>
                      &nbsp;and&nbsp;
                      <span className="underline">operating transparently</span>
                      .
                    </Label>
                    <Checkbox>
                      <label className="flex flex-row items-start">
                        <input
                          id="opt_in_privacy_checkbox"
                          checked={state.opt_in_privacy_checkbox}
                          name="opt_in_privacy_checkbox"
                          onChange={handleChange}
                          required={true}
                          type="checkbox"
                        />
                        <div>
                          Yes, I have read the{" "}
                          <a href="/privacy-policy" target="_blank">
                            privacy
                          </a>{" "}
                          and{" "}
                          <a href="/terms-of-service" target="_blank">
                            terms of use
                          </a>{" "}
                          policies, and I consent to receiving further emails.
                          <Required>*</Required>
                        </div>
                      </label>
                    </Checkbox>
                    <div className="l:w-1/4">
                      <Submit>Continue</Submit>
                    </div>
                  </fieldset>
                </form>
              )}
              {sendStatus === "sending" && (
                <div className="sending">
                  <AnimationContainer>
                    <Lottie options={animationOptions} height={72} width={72} />
                  </AnimationContainer>
                  <StyledBlock>
                    <h5>Sending Email Verification...</h5>
                  </StyledBlock>
                </div>
              )}
              {sendStatus === "sent" && (
                <>
                  <div className="flex flex-col items-center">
                    <div className="mx-auto my-6">
                      <img src={MailIcon} alt="mail icon" />
                    </div>
                    <Header>
                      <h1>
                        Awesome!
                        <br />
                        Now, check your inbox
                      </h1>
                    </Header>
                    <div className="font-body text-white text-sm text-center py-4">
                      Confirm your email address, {state.email}, to continue
                      <br />
                      <hr className="faded my-6" />
                      Problems?{" "}
                      <Link
                        to="/contact"
                        className="underline text-dodgeblue-light"
                      >
                        Contact us
                      </Link>{" "}
                      and we’ll get things sorted for you.
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="hero-image">
              <img src={page.featuredImage.url} />
            </div>
          </div>
        </Container>
      </BasicBackgroundSection>
    </ModalLayout>
  )
}

export default Start

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/start" }) {
        ...PageData
      }
    }
  }
`
